import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { VeterinariansBannerStyles } from './styles';
import { EyeOutlined } from '@ant-design/icons';
import defaultAvatar from '../../images/avatar-default-blue.png';
import { getAllVetApi } from '../../api/getAllVet';
import { shuffleArray } from '../../tools';

const VeterinariansBanner: FC<VeterinariansBannerProps> = () => {
  const [vet, setVetBanner] = useState<unknown>();

  useEffect(() => {
    getAllVetApi().then((res) => {
      const randomizedArray = shuffleArray(res?.vets);
      const displayedItems = randomizedArray.slice(0, 6);
      setVetBanner(displayedItems);
    });
  }, []);

  return (
    <VeterinariansBannerStyles id="veterinarians">
      <div className="feature-content">
        <div className="title">veterinarians</div>
        <div className="sub-title pav-title">We are here for you</div>
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html:
              'Select your preferred vet, or for urgent issues choose the next available vet.',
          }}
        />
      </div>
      <div className="vet-section">
        <Row gutter={[25, 25]}>
          {vet?.map((item) => (
            <Col md={8} xs={24} className="vet-content">
              {item.avatar ? (
                <img src={item.avatar} className="vet-img" />
              ) : (
                <div className="vet-img">
                  <img src={defaultAvatar} className="vet-icon" />
                </div>
              )}
              <Button
                className="btn-view"
                href={`/veterinarians/${item.profileSlug}`}
                onClick={() => {
                  localStorage.setItem('profileSlug', item.profileSlug);
                }}
              >
                <EyeOutlined />
              </Button>
              <div className="vet-name">{`${item.firstName} ${item.lastName}`}</div>
            </Col>
          ))}
        </Row>
      </div>
      <div className="btn-div">
        <Button type="primary" className="btn" href="/veterinarians">
          View more vets
        </Button>
      </div>
    </VeterinariansBannerStyles>
  );
};

VeterinariansBanner.defaultProps = {};

export default VeterinariansBanner;
